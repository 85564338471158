/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.4.1/dist/jquery.min.js
 * - /npm/@yaireo/dragsort@1.2.0/dist/dragsort.min.js
 * - /npm/swiper@4.0.6/dist/js/swiper.min.js
 * - /npm/popper.js@1.16.0/dist/umd/popper.min.js
 * - /npm/js-cookie@2.2.1/src/js.cookie.min.js
 * - /npm/bootstrap@4.4.1/dist/js/bootstrap.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
